const prefix = 'CATEGORY';
export const actionTypes = {
  setCategoryState: `${prefix}_SET_CATEGORY_STATE`,
  resetCategoryState: `${prefix}_RESET_CATEGORY_STATE`,
};

export const setCategoryState = state => dispatch => {
  dispatch({type: actionTypes.setCategoryState, payload: state});
};

export const resetCategoryState = () => dispatch => {
  dispatch({type: actionTypes.resetCategoryState});
};
