import {actionTypes} from '~actions/category';
import {defaultPagination} from '~constants/product-list';

export const initState = {
  products: [],
  pagination: {...defaultPagination},
  total: 0,
  categoryMenu: null,
  position: 0,
  pathname: '',
  search: '',
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.setCategoryState: {
      return {...state, ...payload};
    }
    case actionTypes.resetCategoryState: {
      return {...initState};
    }
    default:
      return state;
  }
};
