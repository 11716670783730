import React from 'react';
import {Provider} from 'react-redux';
import {createStore as reduxCreateStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {FacebookProvider, CustomChat} from 'react-facebook';
import rootReducer from '.';

const logger = createLogger({
  level: 'info',
  collapsed: true,
});

const middleware = [thunkMiddleware, logger];
const initialState = {};

const createStore = () => reduxCreateStore(rootReducer, initialState, applyMiddleware(...middleware));

const ReduxWrapper = ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();

  return (
    <>
      <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID || 'app_id'} chatSupport>
        <Provider store={store}>{element}</Provider>
        <CustomChat pageId={process.env.GATSBY_FACEBOOK_PAGE_ID} minimized={false} />
      </FacebookProvider>
    </>
  );
};

export default ReduxWrapper;
