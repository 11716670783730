import moment from 'moment';
import {youtubePrefix} from '~constants/product-detail';

export const parseRouter = (routerName, key) => {
  if (!routerName) return {};

  const arr = routerName.split(`.${key}.`);
  if (arr.length > 2) {
    arr.slice(-2);
  }

  return {name: arr[0].replace(/-/g, ' '), id: arr[1]};
};

export const objectEmpty = obj => obj === undefined || obj == null || (obj && Object.keys(obj).length === 0);

export const parseAttributeValue = routerName => {
  if (!routerName) return {};

  const arr = routerName.split(`.`);
  if (arr.length > 2) {
    arr.slice(-2);
  }

  return {name: arr[0].replace(/-/g, ' '), id: arr[1]};
};

export const getYoutubeThumbnail = url => {
  if (!url) return 'http://i3.ytimg.com/vi/not_have/maxresdefault.jpg';
  const youtubeId = url.substring(youtubePrefix.length);
  return `http://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`;
};

export const buildImageUrl = (img = '', size = '100x100') => {
  if (img.indexOf('//') === 0) {
    return img;
  }
  if (img.indexOf('http') === 0) {
    return img;
  }
  return `${process.env.GATSBY_IMAGE_URL}/${size}${img}`;
};

export const getTextColor = hexColor => {
  if (!hexColor) return 'white';
  const hexArr = [1, 3, 5].map(i => hexColor.substring(i + 1, i + 3));
  const brightness = Math.round(
    (parseInt(hexArr[0], 16) * 299 + parseInt(hexArr[1], 16) * 587 + parseInt(hexArr[2], 16) * 114) / 1000
  );
  return brightness > 125 ? 'black' : 'white';
};

export const parseNameToUrl = str => {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd');
};

export const generateTreeList = (rootParent, remainData) => {
  if (!Array.isArray(remainData)) return [];
  const fullTree = rootParent;
  fullTree.forEach(x => {
    const children = remainData.filter(y => y.parentId === x.id);
    if (children.length <= 0) return;
    const remainChildren = remainData.filter(y => y.parentId !== x.id);
    x.children = generateTreeList(children, remainChildren);
  });
  return fullTree;
};

export const convertCategoryTreeData = (initialData, treeData) => {
  return (treeData || []).map(x => {
    const currentItem = {
      id: x.id,
      title: x.name,
      link: x.link,
      logoUrl: x.logoUrl,
    };
    if (!!x.children && x.children.length > 0) {
      currentItem.children = convertCategoryTreeData(initialData, x.children);
    }
    return currentItem;
  });
};

export const convertStringToDate = date => {
  if (!date) return undefined;
  return new Date(date);
};

export const convertDateToUTC = date => {
  if (!date) return undefined;
  return moment(new Date(date)).utc().format('YYYY-MM-DDTHH:mm:ssZ');
};

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  if (!date) return undefined;
  return moment(typeof date === 'string' ? convertStringToDate(date) : date).format(format);
};

export const convertDateByFormat = (date, format = 'MM/DD/YYYY') => {
  if (!date) return undefined;
  return new Date((typeof date === 'string' ? moment(convertStringToDate(date)) : date).format(format));
};

export const disabledDate = (current, invalidDate) => {
  if (!current) return true;

  let isValid = true;
  if (invalidDate && invalidDate.length > 0)
    isValid = invalidDate.every(dateObj => {
      const currentDatetime = convertDateByFormat(current).getTime();
      if (
        convertDateByFormat(dateObj.dateFrom).getTime() <= currentDatetime &&
        currentDatetime <= convertDateByFormat(dateObj.dateTo).getTime()
      )
        return false;

      return true;
    });

  return current < moment().endOf('day') || !isValid;
};

export const toVND = value => {
  return new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(value);
};

export const generateUrlParameters = data => {
  return Object.keys(data)
    .reduce((cur, key) => {
      if (typeof data[key] === 'object' && data[key]?.length > 0) {
        const newCur = cur.concat(data[key].map(x => `${key}=${encodeURIComponent(x)}`));
        return newCur;
      }
      if (typeof data[key] === 'number' || !!data[key]) {
        cur.push([key, data[key]].map(encodeURIComponent).join('='));
      }
      return cur;
    }, [])
    .join('&');
};

export const numberToCurrency = value => {
  return value ? Number(value).toLocaleString('vi-VN') : '';
};

export const mapAddressData = data => data.map(x => ({label: x.name, value: x.id}));

export const startOfDay = (date, isDate = false) => {
  const startDate = moment(date).startOf('day');
  return isDate ? new Date(startDate) : startDate;
};

export const getActualRentalDate = (date, incNum) => {
  const d = new Date(date);
  return convertDateToUTC(startOfDay(d.setDate(d.getDate() + incNum)));
};

export const getLocalRentalDate = (dateStr, incNum) => {
  const date = new Date(dateStr);
  return new Date(date.setDate(date.getDate() + incNum));
};

export const isValidRentalDates = (rentalDates, invalidDates) => {
  try {
    const now = startOfDay(new Date(), true).getTime();
    const startDate = rentalDates[0].getTime();
    const endDate = rentalDates[1].getTime();

    return (
      !startDate < now &&
      invalidDates.every(x => {
        const invalidDateFrom = new Date(x.dateFrom).getTime();
        const inValidDateTo = new Date(x.dateTo).getTime();

        return (
          !(invalidDateFrom <= startDate && startDate <= inValidDateTo) &&
          !(invalidDateFrom <= endDate && endDate <= inValidDateTo) &&
          !(startDate <= invalidDateFrom && inValidDateTo <= endDate)
        );
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed to validate rental dates: ', e);
  }

  return false;
};

export const dateDiff = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;
  const sDate = startOfDay(typeof startDate === 'string' ? new Date(startDate) : startDate);
  const eDate = startOfDay(typeof endDate === 'string' ? new Date(endDate) : endDate);
  return Math.round((eDate - sDate) / (1000 * 60 * 60 * 24));
};

export const getTime = date => {
  if (typeof date === 'string') return new Date(date).getTime();
  return date.getTime();
};

export const sortString = (array, fieldSort) => {
  if (!Array.isArray(array)) return array;

  const result = array.sort((a, b) => {
    return a[fieldSort].localeCompare(b[fieldSort], undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
  return result;
};

export const toWalletCoin = value => {
  return new Intl.NumberFormat('vi-VN').format(value);
};

export default {};
