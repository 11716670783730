import notification, {NotifyTypes} from './modals/notification';

const getFullUri = link => {
  return `${process.env.GATSBY_API_URL ?? '/api'}${link}`;
};

const getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Consumer: true,
  };
};

const parseErrorToMessage = async error => {
  try {
    if (error) {
      const message = await error.json();
      if (message) {
        return message.message;
      }
    }
  } catch {}
  return 'Network error';
};

export const handleErrorException = async error => {
  switch (error.status) {
    case 400:
      break;
    case 401:
      return undefined;
    case 403:
    case 404:
    case 409:
      break;
    default:
      break;
  }
  const message = await parseErrorToMessage(error);
  notification(NotifyTypes.ERROR, {message});
  return undefined;
};

export const get = async url => {
  try {
    const uri = getFullUri(url);
    const headers = getRequestHeaders();
    const response = await fetch(uri, {
      credentials: 'include',
      method: 'GET',
      headers,
    });
    if (!!response && response.status === 200) {
      return await response.json();
    }
    await handleErrorException(response);
  } catch (err) {
    await handleErrorException(err);
  }
  return undefined;
};

export const postRequest = async (url, payload) => {
  const uri = getFullUri(url);
  const headers = getRequestHeaders();
  const response = await fetch(uri, {
    credentials: 'include',
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  });
  return response;
};

export const post = async (url, payload) => {
  try {
    const uri = getFullUri(url);
    const headers = getRequestHeaders();
    const response = await fetch(uri, {
      credentials: 'include',
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    if (!!response && (response.status === 200 || response.status === 201)) {
      return await response.json();
    }
    await handleErrorException(response);
  } catch (err) {
    await handleErrorException(err);
  }
  return undefined;
};

export const put = async (url, payload) => {
  try {
    const uri = getFullUri(url);
    const headers = getRequestHeaders();
    const response = await fetch(uri, {
      credentials: 'include',
      method: 'PUT',
      headers,
      body: JSON.stringify(payload),
    });
    if (!!response && response.status === 200) {
      return await response.json();
    }
    await handleErrorException(response);
  } catch (err) {
    await handleErrorException(err);
  }
  return undefined;
};

export const remove = async (url, payload) => {
  try {
    const uri = getFullUri(url);
    const headers = getRequestHeaders();
    const response = await fetch(uri, {
      credentials: 'include',
      method: 'DELETE',
      headers,
      body: JSON.stringify(payload),
    });
    if (!!response && response.status === 200) {
      return {status: 200};
    }
    await handleErrorException(response);
  } catch (err) {
    await handleErrorException(err);
  }
  return undefined;
};

export const uploadFile = async (url, payload) => {
  let checkedUrl = url;

  if (url.indexOf('http') !== 0 && url.indexOf('//') !== 0) {
    checkedUrl = `//${url}`;
  }

  try {
    const response = await fetch(checkedUrl, {
      method: 'PUT',
      body: payload,
    });

    if (!!response && (response.status === 200 || response.status === 201)) {
      return await response.json();
    }

    await handleErrorException(response);
  } catch (error) {
    await handleErrorException(error);
  }
  return undefined;
};

export default {};
