import {actionTypes} from '~actions/user';

export const initState = {
  user: {},
  consumer: {},
  isGuest: true,
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.login: {
      return {...state, user: payload, isGuest: false};
    }
    case actionTypes.register: {
      return {...state, user: payload, isGuest: false};
    }
    case actionTypes.logout: {
      return {...initState, isGuest: true};
    }
    case actionTypes.relogin: {
      return {...state, user: payload.user, consumer: payload.customer, isGuest: false};
    }
    default:
      return state;
  }
};
