import {SortTabTypes} from '~utils/enum';

export const filterTypes = [
  {
    key: SortTabTypes.Newest,
    title: 'Mới nhất',
  },
  {
    key: SortTabTypes.TopSeller,
    title: 'Bán chạy',
  },
  {
    key: SortTabTypes.PriceAsc,
    title: 'Giá từ thấp đến cao',
  },
  {
    key: SortTabTypes.PriceDesc,
    title: 'Giá từ cao đến thấp',
  },
];

export const defaultPagination = {
  limit: 30,
  page: 1,
};

export const filterPriceTypes = [
  {
    urlKey: 'rental-prices',
    key: 'rentalPrice',
    title: 'Giá thuê',
    type: 'PRICE_TYPE_RENTAL',
  },
  {
    urlKey: 'sale-prices',
    key: 'salePrice',
    title: 'Giá bán',
    type: 'PRICE_TYPE_SALE',
  },
];

export default {};
