export const RouterKeys = Object.freeze({
  Category: 'cat',
  Attribute: 'attr',
  Product: 'prod',
});

export const SortTabTypes = Object.freeze({
  Newest: 'newest',
  TopSeller: 'top_seller',
  PriceDesc: 'price_desc',
  PriceAsc: 'price_asc',
});

export const BannerTypes = Object.freeze({
  Hot: 'hot',
  Big: 'big',
  Small: 'small',
  Occasion: 'icon1',
  Style: 'icon2',
});

export const CollectionTypes = Object.freeze({
  None: 'COLLECTION_TYPE_UNSPECIFIED',
  Trending: 'COLLECTION_TYPE_TRENDING',
  NewArrival: 'COLLECTION_TYPE_NEWARRIVAL',
  Sale: 'COLLECTION_TYPE_SALE',
});

export const ReviewStarTypes = Object.freeze({
  fiveStar: '5',
  fourStar: '4',
  threeStar: '3',
  twoStar: '2',
  oneStar: '1',
});

export const UserProviders = Object.freeze({
  None: 'UNSPECIFIED_PROVIDER',
  Default: 'DEFAULT_PROVIDER',
  Facebook: 'FACEBOOK_PROVIDER',
  Google: 'GOOGLE_PROVIDER',
  Apple: 'APPLE_PROVIDER',
});

export const OrderTabTypes = Object.freeze({
  // cancel ...
  Cancel: 'CONSUMER_ORDER_TAB_TYPE_CANCEL',
  // done ...
  Done: 'CONSUMER_ORDER_TAB_TYPE_DONE',
  // delivering ...
  Delivering: 'CONSUMER_ORDER_TAB_TYPE_DELIVERING',
  // not paid ...
  NotPaid: 'CONSUMER_ORDER_TAB_TYPE_NOT_PAID',
});

export const AddressType = Object.freeze({
  Home: ['ADDRESS_TYPE_HOME', 'Nhà riêng'],
  Office: ['ADDRESS_TYPE_COMPANY', 'Văn phòng'],
});

export const CartTypes = Object.freeze({
  // rent ...
  Rent: 'rent',
  // sale ...
  Sale: 'sale',
});

export const PaymentType = Object.freeze({
  CASH: ['PAYMENT_TYPE_CASH', 'Thanh toán bằng tiền mặt tại Rentzy'],
  BANK_TRANSFER: ['PAYMENT_TYPE_BANK_TRANSFER', 'Chuyển tiền qua ngân hàng'],
  MOMO: ['PAYMENT_TYPE_MOMO', 'Thanh toán bằng ví MoMo'],
  ZALO_PAY: ['PAYMENT_TYPE_ZALO_PAY', 'Thanh toán bằng ví ZaloPay'],
  ATM_INTERNET_BANKING: ['PAYMENT_TYPE_ATM_INTERNET_BANKING', 'Thẻ ATM nội địa/Internet Banking'],
  VISA_MASTER_JCB: ['PAYMENT_TYPE_VISA_MASTER_JCB', 'Thanh toán bằng thẻ quốc tế Visa, Master, JCB'],
});

export default {};
