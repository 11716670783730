import {get, post, put, remove} from '~utils/api';

const prefix = 'ADDRESS';
export const actionTypes = {
  getList: `${prefix}_GET_LIST_SUCCESS`,
  createAddress: `${prefix}_CREATE_ADDRESS_SUCCESS`,
  updateAddress: `${prefix}_UPDATE_ADDRESS_SUCCESS`,
  deleteAddress: `${prefix}_DELETE_ADDRESS_SUCCESS`,
  error: `${prefix}_ERROR`,
};

export const getList = () => async dispatch => {
  try {
    const res = await get(`/v1/customer/address`);
    if (res) {
      dispatch({type: actionTypes.getList, payload: res});
      return res;
    }
    dispatch({type: actionTypes.getList, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const createAddress = request => async dispatch => {
  try {
    const res = await post(`/v1/customer/address`, request);
    if (res) {
      dispatch({type: actionTypes.createAddress, payload: res});
      return res;
    }
    dispatch({type: actionTypes.createAddress, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const updateAddress = request => async dispatch => {
  try {
    const res = await put(`/v1/customer/address/${request.id}`, request);
    if (res) {
      dispatch({type: actionTypes.updateAddress, payload: res});
      return res;
    }
    dispatch({type: actionTypes.updateAddress, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const deleteAddress = id => async dispatch => {
  try {
    const res = await remove(`/v1/customer/address/${id}`, {id});
    if (res) {
      dispatch({type: actionTypes.deleteAddress, payload: res});
      return res;
    }
    dispatch({type: actionTypes.deleteAddress, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
