import {loaderActionType} from '~actions/loader';

export const initState = {
  loading: false,
};

export default (state = initState, {type}) => {
  switch (type) {
    case loaderActionType.SHOW_LOADER:
      return {...state, loading: true};
    case loaderActionType.HIDE_LOADER:
      return {...state, loading: false};
    default:
      return state;
  }
};
