import {actionTypes} from '~actions/address';

export const initState = {
  addresses: [],
  total: 0,
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.getList: {
      return {...state, addresses: payload.items ?? [], total: payload.total ?? 0};
    }
    case actionTypes.createAddress:
    case actionTypes.updateAddress:
    case actionTypes.deleteAddress:
    default:
      return state;
  }
};
