import {get} from '~utils/api';

const prefix = 'BANNER';
export const actionTypes = {
  getAllBanners: `${prefix}_GET_ALL_BANNERS`,
  error: `${prefix}_ERROR`,
};

export const getAllBanners = () => async dispatch => {
  try {
    const res = await get('/v1/banner/list?pagination.limit=1000&pagination.page=1&&filter=RUNNING_BANNER');

    if (res) {
      dispatch({type: actionTypes.getAllBanners, payload: res});
      return res.banners;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
