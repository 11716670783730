import {combineReducers} from 'redux';
import product from './product';
import user from './user';
import cart from './cart';
import banner from './banner';
import address from './address';
import addressInfo from './address-info';
import loader from './loader';
import category from './category';

export default combineReducers({product, loader, user, cart, banner, address, addressInfo, category});
