import {get, post, remove} from '~utils/api';
import {generateUrlParameters} from '~utils/helper';

const prefix = 'PRODUCT_DETAIL';
export const actionTypes = {
  getProductDetail: `${prefix}_GET_PRODUCT_DETAIL_SUCCESS`,
  getCurrentPromotion: `${prefix}_GET_PROMOTION_SUCCESS`,
  getFavoriteProducts: `${prefix}_GET_FAVORITE_PRODUCTS`,
  addFavorite: `${prefix}_ADD_FAVORITE`,
  removeFavorite: `${prefix}_REMOVE_FAVORITE`,
  error: `${prefix}_ERROR`,
  keepPagination: `${prefix}_KEEP_PAGINATION_PRODUCTS`,
};

export const getProductDetail = productId => async dispatch => {
  try {
    const res = await get(`/v1/product/product-detail/${productId}`);
    if (res) {
      dispatch({type: actionTypes.getProductDetail, payload: res});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const getCurrentPromotion = () => async dispatch => {
  try {
    const res = await get(`/v1/promotion?is_available=true`);

    if (res) {
      dispatch({type: actionTypes.getCurrentPromotion, payload: res});
      return res.promotions || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
export const keepPaginationState = pagination => dispatch => {
  dispatch({type: actionTypes.keepPagination, payload: pagination});
};
export const getFavoriteProducts = requestParams => async dispatch => {
  try {
    const res = await get(
      `/v1/product/favorite-product/list${requestParams ? `?${generateUrlParameters(requestParams)}` : ''}`
    );

    if (res) {
      dispatch({type: actionTypes.getFavoriteProducts, payload: res});
      return res.items || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const addFavorite = product => async dispatch => {
  try {
    const res = await post(`/v1/product/favorite-product`, {id: product.id});

    if (res) {
      dispatch({type: actionTypes.addFavorite, payload: product});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const removeFavorite = id => async dispatch => {
  try {
    const res = await remove(`/v1/product/favorite-product`, {id});

    if (res) {
      dispatch({type: actionTypes.removeFavorite, payload: id});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
