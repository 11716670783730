import {actionTypes} from '~actions/product';
import {defaultPagination} from '~constants/product-list';

export const initState = {
  data: {},
  promotions: [],
  favoriteProducts: [],
  pagination: defaultPagination,
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.getProductDetail: {
      return {...state, data: payload};
    }
    case actionTypes.getCurrentPromotion: {
      const {items} = payload;
      return {...state, promotions: items || []};
    }
    case actionTypes.getFavoriteProducts: {
      const {items} = payload;
      return {...state, favoriteProducts: items || []};
    }
    case actionTypes.addFavorite: {
      return {...state, favoriteProducts: [...state.favoriteProducts, payload]};
    }
    case actionTypes.keepPagination: {
      return {...state, pagination: payload};
    }
    case actionTypes.removeFavorite: {
      const {favoriteProducts} = state;
      favoriteProducts.splice(
        favoriteProducts.findIndex(x => x.id === payload),
        1
      );
      return {...state, favoriteProducts: [...favoriteProducts]};
    }
    default:
      return state;
  }
};
