module.exports = {
  initialSaleTypes: [
    {
      key: 'rental',
      title: 'Thuê Lẻ',
      content: 'Chọn lịch thuê',
      price: '$60',
      disabled: false,
    },
    // {
    //   key: 'member-ship',
    //   title: 'Thành Viên',
    //   content: 'Chọn mua gói thành viên đang có',
    //   price: 'từ $80',
    //   disabled: false,
    // },
    {
      key: 'sale',
      title: 'Mua ngay',
      content: 'Mua thanh lý',
      price: '$315',
      disabled: false,
    },
  ],
  youtubePrefix: 'https://www.youtube.com/watch?v=',
  youtubeEmbedPrefix: 'https://www.youtube.com/embed',
  dateFormat: 'DD/MM/YYYY',
};
