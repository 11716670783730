import {get, post, remove} from '~utils/api';

const prefix = 'USER';
export const actionTypes = {
  login: `${prefix}_LOGIN_SUCCESS`,
  register: `${prefix}_REGISTER_SUCCESS`,
  logout: `${prefix}_LOGOUT_SUCCESS`,
  relogin: `${prefix}_RELOGIN_SUCCESS`,
  forgotPassword: `${prefix}_FORGOT_PASSWORD_SUCCESS`,
  resetPassword: `${prefix}_RESET_PASSWORD_SUCCESS`,
  error: `${prefix}_ERROR`,
};

export const login = values => async dispatch => {
  try {
    const res = await post(`/v1/user/consumer/login`, values);
    if (res) {
      dispatch({type: actionTypes.login, payload: res});
      return res;
    }
    dispatch({type: actionTypes.login, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
export const register = values => async dispatch => {
  try {
    const res = await post(`/v1/user/consumer/register`, values);
    if (res) {
      dispatch({type: actionTypes.register, payload: res});
      return res;
    }
    dispatch({type: actionTypes.login, payload: {}});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const logout = () => async dispatch => {
  try {
    const res = await remove('/v1/user/consumer/me');
    if (res) {
      dispatch({type: actionTypes.logout});
      return res;
    }
  } catch (err) {
    dispatch({type: actionTypes.error, payload: err});
  }
  return undefined;
};

const getMembershipInfo = async membershipPackageId => {
  try {
    const membershipPackage = await get(`/v1/consumer-membership-package/${membershipPackageId}`);
    return membershipPackage;
  } catch (e) {
    return null;
  }
};

export const relogin = () => async dispatch => {
  try {
    const res = await get('/v1/user/consumer/me');
    if (res) {
      if (res.customer?.membership?.membershipPackageId) {
        const membershipPackage = await getMembershipInfo(res.customer.membership.membershipPackageId);
        if (membershipPackage)
          res.customer.membership = {
            ...res.customer.membership,
            name: membershipPackage.name,
            type: membershipPackage.type,
            color: membershipPackage.color,
          };
      }
      dispatch({type: actionTypes.relogin, payload: res});
      return res;
    }
  } catch (err) {
    dispatch({type: actionTypes.error, payload: err});
  }
  return undefined;
};

export const forgotPassword = email => async dispatch => {
  try {
    const res = await post(`/v1/user/forgot-password`, {email});
    if (res) {
      dispatch({type: actionTypes.forgotPassword, payload: res});
      return res;
    }
    dispatch({type: actionTypes.error});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const resetPassword = request => async dispatch => {
  try {
    const res = await post(`/v1/user/change-forgot-password`, request);
    if (res) {
      dispatch({type: actionTypes.resetPassword, payload: res});
      return res;
    }
    dispatch({type: actionTypes.error});
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
