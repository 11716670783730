export const loaderActionType = {
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_LOADER: `SHOW_LOADER`,
};

export const showLoader = () => dispatch => {
  dispatch({type: loaderActionType.SHOW_LOADER});
};

export const hideLoader = () => dispatch => {
  dispatch({type: loaderActionType.HIDE_LOADER});
};
