import React from 'react';
import {Modal} from 'antd';

export const NotifyTypes = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
});

export default (modalType, {title, message, messages}) => {
  let content = message;
  if (!!messages && messages.length > 0) {
    content = (
      <div>
        {messages.map((x, i) => (
          <p key={i}>{x}</p>
        ))}
      </div>
    );
  }
  Modal[modalType]({
    title,
    content,
    onOk: () => {
      Modal.destroyAll();
    },
  });
};
