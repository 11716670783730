import {actionTypes} from '~actions/cart';
import {dateDiff, getLocalRentalDate} from '~utils/helper';
import {getItemPriceByTotalPrice} from '~utils/price-helper';

export const initState = {
  data: {
    total: 0,
    items: [],
  },
  invalidDates: [],
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.getList: {
      return {
        ...state,
        data: {
          ...payload,
          items:
            payload.items?.map(x => {
              x.originalPrice = getItemPriceByTotalPrice(x.originalPrice, x.quantities);
              x.salePrice = getItemPriceByTotalPrice(x.salePrice, x.quantities);
              x.originalRentalPrice = getItemPriceByTotalPrice(x.originalRentalPrice, x.quantities);
              x.rentalPrice = getItemPriceByTotalPrice(x.rentalPrice, x.quantities, dateDiff(x.dateFrom, x.dateTo));
              x.depositPrice = getItemPriceByTotalPrice(x.depositPrice, x.quantities);
              if (x.dateFrom) {
                x.dateFrom = getLocalRentalDate(x.dateFrom, -1);
              }
              if (x.dateTo) {
                x.dateTo = getLocalRentalDate(x.dateTo, 1);
              }
              return x;
            }) ?? [],
        },
      };
    }
    case actionTypes.getCartFromCookies: {
      return {...state, data: payload};
    }
    case actionTypes.updateCart: {
      const {
        data: {items},
      } = state;
      items[items.findIndex(x => x.id === payload.id)] = payload;
      return {...state, data: {...state.data, items}};
    }
    case actionTypes.deleteCart: {
      const remainData = state.data.items.reduce((result, item) => {
        if (payload.includes(item.id)) {
          const deletedIndex = result.findIndex(x => x.id === item.id);
          result.splice(deletedIndex, 1);
        }
        return result;
      }, state.data.items);
      return {...state, data: {total: remainData.length, items: remainData}};
    }
    case actionTypes.getInvalidDate: {
      const {invalidDates} = payload;
      return {...state, invalidDates: invalidDates || []};
    }
    case actionTypes.error: {
      return {...initState};
    }
    default:
      return state;
  }
};
