import * as ApiService from '~utils/api';

const prefix = 'ADDRESS';
export const actionTypes = {
  GET_LIST_CITY_SUCCESS: `${prefix}_GET_LIST_CITY_SUCCESS`,
  GET_LIST_DISTRICT_SUCCESS: `${prefix}_GET_LIST_DISTRICT_SUCCESS`,
  GET_LIST_WARD_SUCCESS: `${prefix}_GET_LIST_WARD_SUCCESS`,
  CREATE_UPDATE_SUCCESS: `${prefix}CREATE_UPDATE_SUCCESS`,
  CREATE_UPDATE_FAILED: `${prefix}CREATE_UPDATE_FAILED`,
  ERROR: `${prefix}_ERROR`,
};

export const getListCity = () => async dispatch => {
  try {
    const res = await ApiService.get(`/v1/address/city`);
    if (res) {
      dispatch({type: actionTypes.GET_LIST_CITY_SUCCESS, payload: res});
      return res.items || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.ERROR, payload: error});
  }
  return undefined;
};

export const getListDistrict = cityId => async dispatch => {
  try {
    const res = await ApiService.get(`/v1/address/${cityId}/district`);
    if (res) {
      dispatch({type: actionTypes.GET_LIST_DISTRICT_SUCCESS, payload: res});
      return res.items || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.ERROR, payload: error});
  }
  return undefined;
};

export const getListWard = districtId => async dispatch => {
  try {
    const res = await ApiService.get(`/v1/address/${districtId}/ward`);
    if (res) {
      dispatch({type: actionTypes.GET_LIST_WARD_SUCCESS, payload: res});
      return res.items || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.ERROR, payload: error});
  }
  return undefined;
};
