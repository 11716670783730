import {CartTypes} from './enum';
import {dateDiff, getActualRentalDate} from './helper';

export const getItemPriceByTotalPrice = (total, quantities, spanDate = 0) =>
  Math.round(total / (quantities * (1 + 0.1 * spanDate)));

export const getTotalPriceByItemPrice = (product, type) => {
  if (type === CartTypes.Sale) {
    return product.salePrice * product.quantities ?? 0;
  }

  return product.rentalPrice * product.quantities * (1 + 0.1 * (dateDiff(product.dateFrom, product.dateTo) - 2));
};

export const getTotalPriceByPrice = (price, quantities, spanDate = 0) =>
  Math.round(price * quantities * (1 + 0.1 * spanDate));

export const getTotalCarts = (carts, cartType) => {
  return carts.reduce((res, item) => {
    return res + getTotalPriceByItemPrice(item, cartType);
  }, 0);
};

export const getTotalDepositCarts = (carts, cartType) => {
  return carts.reduce((res, item) => {
    return res + (cartType === CartTypes.Sale ? 0 : item.quantities * item.depositPrice);
  }, 0);
};

export const getMembershipPriceByTypes = (product, percentDiscount = 0, membershipConfigs) => {
  let {membershipRentalPrice} = product;
  if (membershipConfigs && membershipConfigs.length > 0) {
    const membershipConfig = membershipConfigs.find(x => x.productId === product.productId);
    if (membershipConfig && membershipConfig.membershipRentalPrice > 0)
      membershipRentalPrice = membershipConfig.membershipRentalPrice;
  }
  return membershipRentalPrice || product.rentalPrice * (1 - percentDiscount / 100);
};

export const getMembershipPrice = (currentCarts, membership, membershipConfigs) => {
  return currentCarts.reduce((result, item) => {
    const curPrice = getMembershipPriceByTypes(item, membership.percentDiscount, membershipConfigs);

    const extraDays = dateDiff(
      getActualRentalDate(new Date(item.dateFrom), 1),
      getActualRentalDate(new Date(item.dateTo), -1)
    );
    const diffDates = extraDays - membership.extendDay + 1;
    return result + getTotalPriceByPrice(curPrice, item.quantities, diffDates > 0 ? diffDates : 0);
  }, 0);
};

export default {};
