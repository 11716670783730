// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-page-about-jsx": () => import("./../../../src/pages/about/[pageAbout].jsx" /* webpackChunkName: "component---src-pages-about-page-about-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-account-address-create-index-jsx": () => import("./../../../src/pages/account/address/create/index.jsx" /* webpackChunkName: "component---src-pages-account-address-create-index-jsx" */),
  "component---src-pages-account-address-edit-[id]-jsx": () => import("./../../../src/pages/account/address/edit/[id].jsx" /* webpackChunkName: "component---src-pages-account-address-edit-[id]-jsx" */),
  "component---src-pages-account-address-index-jsx": () => import("./../../../src/pages/account/address/index.jsx" /* webpackChunkName: "component---src-pages-account-address-index-jsx" */),
  "component---src-pages-account-change-password-index-jsx": () => import("./../../../src/pages/account/change-password/index.jsx" /* webpackChunkName: "component---src-pages-account-change-password-index-jsx" */),
  "component---src-pages-account-favorite-index-jsx": () => import("./../../../src/pages/account/favorite/index.jsx" /* webpackChunkName: "component---src-pages-account-favorite-index-jsx" */),
  "component---src-pages-account-my-wallet-index-jsx": () => import("./../../../src/pages/account/my-wallet/index.jsx" /* webpackChunkName: "component---src-pages-account-my-wallet-index-jsx" */),
  "component---src-pages-account-notification-index-jsx": () => import("./../../../src/pages/account/notification/index.jsx" /* webpackChunkName: "component---src-pages-account-notification-index-jsx" */),
  "component---src-pages-account-order-history-index-jsx": () => import("./../../../src/pages/account/order-history/index.jsx" /* webpackChunkName: "component---src-pages-account-order-history-index-jsx" */),
  "component---src-pages-account-order-history-order-id-jsx": () => import("./../../../src/pages/account/order-history/[orderId].jsx" /* webpackChunkName: "component---src-pages-account-order-history-order-id-jsx" */),
  "component---src-pages-account-profile-index-jsx": () => import("./../../../src/pages/account/profile/index.jsx" /* webpackChunkName: "component---src-pages-account-profile-index-jsx" */),
  "component---src-pages-category-link-jsx": () => import("./../../../src/pages/[categoryLink].jsx" /* webpackChunkName: "component---src-pages-category-link-jsx" */),
  "component---src-pages-checkout-cart-jsx": () => import("./../../../src/pages/checkout/cart.jsx" /* webpackChunkName: "component---src-pages-checkout-cart-jsx" */),
  "component---src-pages-checkout-index-jsx": () => import("./../../../src/pages/checkout/index.jsx" /* webpackChunkName: "component---src-pages-checkout-index-jsx" */),
  "component---src-pages-checkout-order-success-js": () => import("./../../../src/pages/checkout/order-success.js" /* webpackChunkName: "component---src-pages-checkout-order-success-js" */),
  "component---src-pages-checkout-payment-jsx": () => import("./../../../src/pages/checkout/payment.jsx" /* webpackChunkName: "component---src-pages-checkout-payment-jsx" */),
  "component---src-pages-customer-care-customer-care-link-jsx": () => import("./../../../src/pages/customer-care/[customerCareLink].jsx" /* webpackChunkName: "component---src-pages-customer-care-customer-care-link-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../../../src/pages/forgot-password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-page-info-jsx": () => import("./../../../src/pages/info/[pageInfo].jsx" /* webpackChunkName: "component---src-pages-info-page-info-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-member-ship-jsx": () => import("./../../../src/pages/member-ship.jsx" /* webpackChunkName: "component---src-pages-member-ship-jsx" */),
  "component---src-pages-onsale-jsx": () => import("./../../../src/pages/onsale.jsx" /* webpackChunkName: "component---src-pages-onsale-jsx" */),
  "component---src-pages-product-product-link-jsx": () => import("./../../../src/pages/product/[productLink].jsx" /* webpackChunkName: "component---src-pages-product-product-link-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-seft-self-link-jsx": () => import("./../../../src/pages/seft/[selfLink].jsx" /* webpackChunkName: "component---src-pages-seft-self-link-jsx" */)
}

