import {actionTypes} from '~actions/address-info';
import {sortString} from '~utils/helper';

export const initState = {
  city: [],
  district: [],
  ward: [],
  error: null,
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.GET_LIST_CITY_SUCCESS: {
      const {items} = payload;
      return {...state, city: sortString(items, 'name'), district: [], ward: []};
    }
    case actionTypes.GET_LIST_DISTRICT_SUCCESS: {
      const {items} = payload;
      return {...state, district: sortString(items, 'name'), ward: []};
    }
    case actionTypes.GET_LIST_WARD_SUCCESS: {
      const {items} = payload;
      return {...state, ward: sortString(items, 'name')};
    }
    case actionTypes.ERROR: {
      const {error} = payload;
      return {...state, error};
    }
    default:
      return state;
  }
};
