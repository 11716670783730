import {get, post, put, remove} from '~utils/api';

const prefix = 'CART';
export const actionTypes = {
  getList: `${prefix}_GET_LIST`,
  updateCart: `${prefix}_UPDATE_CART`,
  deleteCart: `${prefix}_DELETE_CART`,
  getInvalidDate: `${prefix}_GET_INVALID_DATE`,
  error: `${prefix}_ERROR`,
  getCartFromCookies: `${prefix}_GET_CART_FROM_COOKIES`,
};

export const getCartFromCookies = cart => dispatch => {
  dispatch({
    type: actionTypes.getCartFromCookies,
    payload: {
      items: cart,
      total: cart.length,
    },
  });
};

export const getCarts = () => async dispatch => {
  try {
    const res = await get('/v1/cart/cart-item/list');
    if (res) {
      dispatch({type: actionTypes.getList, payload: res});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const addCart = request => async dispatch => {
  try {
    const res = await post('/v1/cart', request);
    if (res) {
      dispatch(getCarts());
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const updateCart = request => async dispatch => {
  try {
    const res = await put(`/v1/cart/cart-item/${request.id}`, {
      id: request.id,
      quantities: request.quantities,
      dateFrom: request.dateFrom,
      dateTo: request.dateTo,
    });
    if (res) {
      dispatch({type: actionTypes.updateCart, payload: request});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const deleteCart = ids => async dispatch => {
  try {
    const res = await remove('/v1/cart/cart-item', {ids});
    if (res) {
      dispatch({type: actionTypes.deleteCart, payload: ids});
      return res;
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};

export const getInvalidDate = request => async dispatch => {
  try {
    const res = await post('/v1/cart/invalid-rent-date/list', request);
    if (res) {
      dispatch({type: actionTypes.getInvalidDate, payload: res});
      return res.invalidDates || [];
    }
  } catch (error) {
    dispatch({type: actionTypes.error});
  }
  return undefined;
};
