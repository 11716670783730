import {actionTypes} from '~actions/banner';

export const initState = {
  banners: [],
};

export default (state = initState, {type, payload}) => {
  switch (type) {
    case actionTypes.getAllBanners: {
      return {
        ...state,
        banners: payload.banners ?? [],
      };
    }
    default:
      return state;
  }
};
